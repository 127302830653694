import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import './advantagesBlock.css';
import '../../styles/card.css';

const advantagesArr = [
  {
    title: 'Опыт и Профессионализм',
    description:
      'Наша команда состоит из квалифицированных специалистов с многолетним опытом работы в клининговой сфере.',
  },
  {
    title: 'Качество и Надежность',
    description:
      'Мы используем современные технологии и экологически чистые средства для достижения наилучших результатов.',
  },
  {
    title: 'Быстрота и Эффективность',
    description:
      'Мы ценим ваше время и гарантируем оперативное выполнение всех заказов без ущерба для качества.',
  },
  {
    title: 'Индивидуальный подход',
    description:
      'Мы разрабатываем индивидуальные решения для каждого клиента, учитывая все его пожелания и требования.',
  },
];

const AnimatedCard = ({ title, description }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const animationProps = useSpring({
    opacity: inView ? 1 : 0,
    delay: 200,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
  });

  return (
    <animated.div style={animationProps} ref={ref} className="card">
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-description">{description}</p>
      </div>
    </animated.div>
  );
};

export const AdvantagesBlock = () => {
  return (
    <div className="advantages">
      <h2>Наши Преимущества</h2>
      <div className="advantages__cards">
        {advantagesArr.map((data, index) => (
          <AnimatedCard
            key={index}
            title={data.title}
            description={data.description}
          />
        ))}
      </div>
    </div>
  );
};
