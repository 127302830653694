import './appToolbar.css';
import logo from '../../assets/images/services/logo.jpg';

export const AppToolbar = () => {
  return (
    <nav className="header_toolbar">
      <img className="header_logo" src={logo} alt="kelin_clean.kz logo" />
      <div className="header_navTools">
        <div className="header_phone">
          <span>+7 (747) 610 0241</span>
        </div>
        <div className="header_whatsapp">
          <a
            className="link"
            href="https://api.whatsapp.com/send?phone=77476100241"
            target="_blank"
            rel="noreferrer"
          >
            Whatsapp
          </a>
        </div>
        <div>
          <a className="link" href="#FAQ">
            Вопросы и ответы
          </a>
        </div>
      </div>
    </nav>
  );
};
