import { Button } from '../Button/Button';
import { useState } from 'react';
import './leadInfo.css';
import { OrderModalForm } from '../OrderModalForm/OrderModalForm';

export const LeadInfo = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const showModal = () => {
    return setModalIsOpen(true);
  };

  const closeModal = () => {
    return setModalIsOpen(false);
  };

  return (
    <div className="lead_info">
      <OrderModalForm isOpen={modalIsOpen} onRequestClose={closeModal} />
      <div className="lead_text_block ">
        <h2>Ваш надежный партнер в создании идеальной чистоты</h2>
        <p className="lead__text">
          Доверьтесь профессионалам и наслаждайтесь безупречной чистотой вашего
          пространства!
        </p>
        <div className="lead_button" onClick={() => showModal()}>
          <Button color="primary" size="size-20">
            Заказать уборку
          </Button>
        </div>
      </div>
    </div>
  );
};
