import React, { useState } from 'react';
import './FaqPage.css';

const faqs = [
  {
    question: 'Как часто рекомендуется проводить регулярную уборку?',
    answer:
      'Регулярную уборку рекомендуется проводить раз в неделю для поддержания чистоты и гигиены в вашем доме или офисе.',
  },
  {
    question: 'Какие документы вы предоставляете для юридических лиц?',
    answer:
      ' Для юридических лиц мы предоставляем все необходимые документы, включая договор на оказание услуг, акты выполненных работ и счета-фактуры.',
  },

  {
    question: 'Какие моющие средства вы используете?',
    answer:
      'Мы используем только высококачественные и безопасные моющие средства, которые не наносят вреда здоровью людей и домашних животных.',
  },
  {
    question: 'Могу ли я отменить или перенести заказ?',
    answer:
      'Да, вы можете отменить или перенести заказ, связавшись с нами не менее чем за 24 часа до назначенного времени уборки.',
  },
  {
    question: 'Нужно ли мне быть дома во время уборки?',
    answer:
      ' Нет, ваше присутствие необязательно. Вы можете оставить ключи нашим специалистам или договориться о доступе к вашему помещению удобным для вас способом.',
  },
  {
    question: 'Могу ли я заказать уборку на выходные или праздничные дни?',
    answer:
      'Да, мы работаем без выходных и предоставляем услуги уборки в любые дни недели, включая праздники.',
  },
];

export const FAQPage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq_block" id="FAQ">
      <div className="faq-container">
        <h2 className="faq-title">Вопросы и Ответы</h2>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div className="faq-item" key={index}>
              <div className="faq-question" onClick={() => handleToggle(index)}>
                {faq.question}
              </div>
              {openIndex === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
