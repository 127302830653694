import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import { Button } from '../Button/Button';
import './servicesBlock.css';
import { OrderModalForm } from '../OrderModalForm/OrderModalForm';

const servicesArr = [
  { title: 'Уборка квартиры' },
  { title: 'Уборка дома' },
  { title: 'Уборка офиса' },
  { title: 'Уборка коттеджей' },
  { title: 'Уборка после ремонта' },
  { title: 'Уборка склада' },
  { title: 'Мойка окон' },
];

const AnimatedService = ({ title, delay }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const animationProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
    delay: inView ? delay : 0,
  });

  return (
    <animated.li style={animationProps} ref={ref}>
      <div className="service_cards_elements">{title}</div>
    </animated.li>
  );
};

const AnimatedButton = ({ children }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const animationProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'scale(1)' : 'scale(0.8)',
    config: { tension: 170, friction: 26 },
  });

  return (
    <animated.div style={animationProps} ref={ref}>
      {children}
    </animated.div>
  );
};

export const ServicesBlock = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const showModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="services_block" id="services">
      <OrderModalForm isOpen={modalIsOpen} onRequestClose={closeModal} />
      <div className="services_cards">
        <h2>Клининговые услуги</h2>
        <ul className="custom-list">
          {servicesArr.map((data, index) => (
            <AnimatedService
              key={index}
              title={data.title}
              delay={index * 200}
            />
          ))}
        </ul>
        <AnimatedButton>
          <div style={{ display: 'inline' }} onClick={showModal}>
            <Button color="primary" size="size-20">
              Заказать уборку
            </Button>
          </div>
        </AnimatedButton>
      </div>
    </div>
  );
};
