import { AdvantagesBlock } from './components/AdvantagesBlock/AdvantagesBlock';
import { AppToolbar } from './components/AppToolbar/AppToolbar';
import { FAQPage } from './components/FaqPage/FaqPage';
import { FooterBlock } from './components/FooterBlock/FooterBlock';
import { ServicesBlock } from './components/ServicesBlock/ServicesBlock';
import { PromoBlock } from './containers/PromoBlock';

function App() {
  return (
    <div>
      <header>
        <AppToolbar />
      </header>
      <main>
        <PromoBlock />
        <AdvantagesBlock />
        <ServicesBlock />
        <FAQPage />
      </main>
      <footer>
        <FooterBlock />
      </footer>
    </div>
  );
}

export default App;
