import React, { useState, useEffect } from 'react';
import './modalForm.css';
import apartments from '../../assets/images/services/apartment.png';
import office from '../../assets/images/services/office.png';
import house from '../../assets/images/services/house.png';
import warehouse from '../../assets/images/services/warehouse.png';
import deepClean from '../../assets/images/services/generalClean.png';
import clean from '../../assets/images/services/clean.png';
import remont from '../../assets/images/services/remont.png';

export const OrderModalForm = ({ isOpen, onRequestClose }) => {
  const [propertyType, setPropertyType] = useState('');
  const [cleaningType, setCleaningType] = useState('');
  const [fields, setFields] = useState({
    name: '',
    phone: '',
    rooms: '',
    floors: '',
    area: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setPropertyType('');
      setCleaningType('');
      setFields({
        name: '',
        phone: '',
        rooms: '',
        floors: '',
        area: '',
      });
      setErrors({});
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if ((name === 'rooms' || name === 'floors' || name === 'area') && value < 0)
      return;
    setFields({
      ...fields,
      [name]: value,
    });
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+7\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Zа-яА-ЯёЁ]+$/;
    return nameRegex.test(name);
  };

  const handleCloseModal = (e) => {
    if (e.target.className.includes('modal-overlay')) {
      onRequestClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = {};
    if (!validatePhone(fields.phone)) {
      formErrors.phone = 'Телефон должен быть в формате +77771122321';
    }
    if (!validateName(fields.name)) {
      formErrors.name = 'Имя должно содержать только буквы';
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const message = `Привет, меня зовут ${fields.name},\n телефон: ${
      fields.phone
    },\n хочу заказать уборку типа недвижимости: ${propertyType},\n тип уборки: ${cleaningType}\n, ${
      fields.rooms ? `количество комнат: ${fields.rooms},` : ''
    }\n ${
      propertyType === 'Дом' && fields.floors ? `этажи: ${fields.floors},` : ''
    }\n общая площадь: ${fields.area}`;

    const whatsappUrl = `https://api.whatsapp.com/send/?phone=%2B77476100241&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, '_blank');

    onRequestClose();
  };

  const renderPropertyFields = () => {
    switch (propertyType) {
      case 'Дом':
        return (
          <>
            <label>Количество комнат</label>
            <input
              type="number"
              name="rooms"
              max={50}
              value={fields.rooms}
              onChange={handleInputChange}
            />
            <label>Этажи</label>
            <input
              type="number"
              name="floors"
              max={100}
              value={fields.floors}
              onChange={handleInputChange}
            />
            <label>Площадь</label>
            <input
              type="number"
              name="area"
              max={10000}
              value={fields.area}
              onChange={handleInputChange}
            />
          </>
        );
      case 'Квартира':
        return (
          <>
            <label>Количество комнат</label>
            <input
              type="number"
              name="rooms"
              max={50}
              value={fields.rooms}
              onChange={handleInputChange}
            />
            <label>Площадь</label>
            <input
              type="number"
              name="area"
              max={1000}
              value={fields.area}
              onChange={handleInputChange}
            />
          </>
        );
      case 'Склад':
        return (
          <>
            <label>Площадь</label>
            <input
              type="number"
              max={50000}
              name="area"
              value={fields.area}
              onChange={handleInputChange}
            />
          </>
        );
      case 'Офис':
        return (
          <>
            <label>Площадь</label>
            <input
              type="number"
              max={10050}
              name="area"
              value={fields.area}
              onChange={handleInputChange}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`modal-overlay ${isOpen ? 'open' : ''}`}
      onClick={handleCloseModal}
    >
      <div className="modal-content">
        <button onClick={onRequestClose} className="close-button">
          X
        </button>
        <h2>Заполните анкету</h2>
        <form onSubmit={handleSubmit} onAbort={handleCloseModal}>
          <label>Имя</label>
          <input
            type="text"
            name="name"
            value={fields.name}
            onChange={handleInputChange}
          />
          {errors.name && <p className="error">{errors.name}</p>}

          <label>Телефон</label>
          <input
            type="tel"
            name="phone"
            value={fields.phone}
            onChange={handleInputChange}
          />
          {errors.phone && <p className="error">{errors.phone}</p>}

          <label>Тип недвижимости</label>
          <div className="radio-group">
            <div
              className={`radio-item ${
                propertyType === 'Дом' ? 'selected' : ''
              }`}
              onClick={() => setPropertyType('Дом')}
            >
              <input
                type="radio"
                id="house"
                name="propertyType"
                value="Дом"
                checked={propertyType === 'Дом'}
                readOnly
              />
              <img src={house} alt="Дом" />
              <label htmlFor="house">Дом</label>
            </div>
            <div
              className={`radio-item ${
                propertyType === 'Квартира' ? 'selected' : ''
              }`}
              onClick={() => setPropertyType('Квартира')}
            >
              <input
                type="radio"
                id="apartment"
                name="propertyType"
                value="Квартира"
                checked={propertyType === 'Квартира'}
                readOnly
              />
              <img src={apartments} alt="Квартира" />
              <label htmlFor="apartment">Квартира</label>
            </div>
            <div
              className={`radio-item ${
                propertyType === 'Офис' ? 'selected' : ''
              }`}
              onClick={() => setPropertyType('Офис')}
            >
              <input
                type="radio"
                id="office"
                name="propertyType"
                value="Офис"
                checked={propertyType === 'Офис'}
                readOnly
              />
              <img src={office} alt="Офис" />
              <label htmlFor="office">Офис</label>
            </div>
            <div
              className={`radio-item ${
                propertyType === 'Склад' ? 'selected' : ''
              }`}
              onClick={() => setPropertyType('Склад')}
            >
              <input
                type="radio"
                id="warehouse"
                name="propertyType"
                value="Склад"
                checked={propertyType === 'Склад'}
                readOnly
              />
              <img src={warehouse} alt="Склад" />
              <label htmlFor="warehouse">Склад</label>
            </div>
          </div>

          {renderPropertyFields()}

          <label>Тип уборки</label>
          <div className="radio-group">
            <div
              className={`radio-item ${
                cleaningType === 'Регулярная' ? 'selected' : ''
              }`}
              onClick={() => setCleaningType('Регулярная')}
            >
              <input
                type="radio"
                id="regular"
                name="cleaningType"
                value="Регулярная"
                checked={cleaningType === 'Регулярная'}
                readOnly
              />
              <img src={clean} alt="Регулярная" />
              <label htmlFor="regular">Регулярная</label>
            </div>
            <div
              className={`radio-item ${
                cleaningType === 'Генеральная' ? 'selected' : ''
              }`}
              onClick={() => setCleaningType('Генеральная')}
            >
              <input
                type="radio"
                id="deep"
                name="cleaningType"
                value="Генеральная"
                checked={cleaningType === 'Генеральная'}
                readOnly
              />
              <img src={deepClean} alt="Генеральная" />
              <label htmlFor="deep">Генеральная</label>
            </div>
            <div
              className={`radio-item ${
                cleaningType === 'Уборка после ремонта' ? 'selected' : ''
              }`}
              onClick={() => setCleaningType('Уборка после ремонта')}
            >
              <input
                type="radio"
                id="post-renovation"
                name="cleaningType"
                value="Уборка после ремонта"
                checked={cleaningType === 'Уборка после ремонта'}
                readOnly
              />
              <img src={remont} alt="Уборка после ремонта" />
              <label htmlFor="post-renovation"> После ремонта</label>
            </div>
          </div>
          <div className="button_block">
            <button type="submit">Отправить</button>
            <button
              type="button"
              className="cancel_btn"
              onClick={onRequestClose}
            >
              Отмена
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
